export interface DynamicFormInputTypeView {
	id: number;
	name: string;
}

export interface DynamicFormView {
	id?: string;
	source: string;
	sourceId: number;
	order: number;
	dynamicFormConfigurations: DynamicFormConfigurationView[];
	dynamicFormMultipleTypeConfigurations: DynamicFormMultipleTypeConfigurationView[];
}

export interface DynamicFormConfigurationView {
	id?: string;
	clientId: string;
	dynamicFormInputTypeId: number;
	dynamicFormInputTypeName: string;
	dynamicFormId: string;
	dynamicFormSource: string;
	label: string;
	tempLabel: string;
	furtherInformation: string;
	characterValidation: number;
	isRequired: boolean;
	order: number;
	options: string;
	dynamicFormConfigurationOptions?: DynamicFormConfigurationOptionView[];
	value?: string;
	optionId?: string;
}

export interface DynamicFormMultipleTypeConfigurationView {
	id?: string;
	source: string;
	sourceId: number;
	order: number;
	multipeTypeConfigurationName: string;
	dynamicFormConfigurations: DynamicFormConfigurationView[];
	selected: boolean;
}

export interface DynamicFormConfigurationOptionView {
	id?: string;
	dynamicFormConfigurationId: string;
	option: string;
}

export interface DynamicFormsView {
	dynamicForms: DynamicFormView[];
}

export interface DynamicFormSource {
	source: string;
	sourceId: number;
	allowMultipleTypesOfAdditionalFields: boolean;
}

export enum EDynamicFormModule {
	Purchasing = 0,
	CapitalAssets = 1,
	Recreation = 2,
}

export enum EDynamicFormInputType {
	FileInput = 1,
	TextInput = 2,
	TextAreaInput = 3,
	Dropdown = 4,
	InformationOnly = 5,
	SectionBreak = 6,
}

export enum EDynamicFormSource {
	BidForm = 0,
	ChangeOrderForm = 1,
	ContractApprovalFom = 2,
	IndividualPayeeForm = 3,
	PORolloverForm = 4,
	PurchaseRequisitionForm = 5,
	VendorForm = 6,
	CapitalAssetWorkOrderForm = 7,
	SpecialEventPermitApplication = 8,
}

export interface DynamicFormValuesForReactiveForm {
	tempLabel: string;
	value: string;
	validators: any[];
}

export interface DynamicFormValues {
	dynamicFormConfigurationId: string;
	value: string;
	dynamicFormConfigurationOptionId: string;
}

export interface DynamicFormMultipleTypeValues {
	dynamicFormValues: DynamicFormValues[];
	dynamicFormMultipleTypeConfigurationId: string;
}

export interface DynamicFormModule {
	moduleName: string;
	moduleId: number;
}
