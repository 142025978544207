import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WorkflowApprovalComponent } from 'app/components/client/workflow/forms/workflow/approval/workflow-approval.component';
import { StepperWorkflowComponent } from 'app/components/client/workflow/forms/stepper/stepper-workflow/stepper-workflow.component';
import { ApproversListComponent } from 'app/components/client/workflow/forms/approvers-list/approvers-list.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CommentsComponent } from 'app/components/client/workflow/forms/comments/comments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from 'app/components/client/workflow/forms/purchasing/upload/file-upload.component';
import { WeeklyTimesheetCommentsComponent } from 'app/components/client/workflow/forms/weekly-timesheet-comments/weekly-timesheet-comments.component';
import { GlPostingComponent } from 'app/components/client/workflow/forms/gl-posting/gl-posting.component';
import { AgGridModule } from 'ag-grid-angular';
import { DynamicFormCreateComponent } from 'app/client/workflow/dynamic-form/dynamic-form-create/dynamic-form-create.component';
import { DynamicFormMultipleTypeCreateComponent } from 'app/client/workflow/dynamic-form/dynamic-form-multiple-type-create/dynamic-form-multiple-type-create.component';
import { DynamicFormListComponent } from 'app/client/workflow/dynamic-form/dynamic-form-list/dynamic-form-list.component';
import { AdditionalFieldConfigurationListComponent } from 'app/client/workflow/modules/purchasing/additional-field-configuration-list/additional-field-configuration-list.component';
import { AdditionalFieldConfigurationCreateComponent } from 'app/client/workflow/modules/purchasing/additional-field-configuration-create/additional-field-configuration-create.component';
import { DynamicFormEmbedComponent } from 'app/client/workflow/dynamic-form/dynamic-form-embed/dynamic-form-embed.component';
import { DynamicFormViewOnlyEmbedComponent } from 'app/client/workflow/dynamic-form/dynamic-form-view-only-embed/dynamic-form-view-only-embed.component';
import { ChipsModule } from 'primeng/chips';
import { ClearedBankPaymentComponent } from 'app/components/client/workflow/forms/cleared-bank-payment/cleared-bank-payment.component';
import { FormWatcherComponent } from 'app/components/client/workflow/forms/form-watcher/form-watcher.component';

@NgModule({
	declarations: [
		WorkflowApprovalComponent,
		StepperWorkflowComponent,
		ApproversListComponent,
		CommentsComponent,
		FileUploadComponent,
		WeeklyTimesheetCommentsComponent,
		GlPostingComponent,
		DynamicFormCreateComponent,
		DynamicFormMultipleTypeCreateComponent,
		DynamicFormListComponent,
		AdditionalFieldConfigurationListComponent,
		AdditionalFieldConfigurationCreateComponent,
		DynamicFormEmbedComponent,
		DynamicFormViewOnlyEmbedComponent,
		ClearedBankPaymentComponent,
		FormWatcherComponent,
	],
	imports: [
		CommonModule,
		MatStepperModule,
		FormsModule,
		ReactiveFormsModule,
		AgGridModule.withComponents([]),
		RouterModule,
		ChipsModule,
	],
	exports: [
		WorkflowApprovalComponent,
		StepperWorkflowComponent,
		ApproversListComponent,
		CommentsComponent,
		FileUploadComponent,
		WeeklyTimesheetCommentsComponent,
		GlPostingComponent,
		DynamicFormCreateComponent,
		DynamicFormMultipleTypeCreateComponent,
		DynamicFormListComponent,
		AdditionalFieldConfigurationListComponent,
		AdditionalFieldConfigurationCreateComponent,
		DynamicFormEmbedComponent,
		DynamicFormViewOnlyEmbedComponent,
		ClearedBankPaymentComponent,
		FormWatcherComponent,
	],
})
export class WorkFlowPluginsModule {}
