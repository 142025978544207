import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { NewCapitalLeaseFormView } from 'app/models/capital-lease/NewCapitalLeaseFormView';

@Injectable({
	providedIn: 'root',
})
export class NewCapitalLeaseService {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });

	private readonly urlPath = `${environment.apiUrl}capital-lease/clients/`;
	private formData: FormData = new FormData();
	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService
	) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/new-capital-lease-form`, this.setupRequest(request))
			.pipe();
	}

	private setupRequest(request: any) {
		this.formData = new FormData();
		request.capitalLeaseFormInOutFlowPayments = [].concat.apply(
			[],
			[
				request.outflowVariablePaymentsArray,
				request.outflowOtherPaymentsArray,
				request.inflowVariableAndOtherPaymentsArray,
			]
		);
		return this.formData.append('model', JSON.stringify(request));
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}`, this.setupRequest(request))
			.pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${this.urlPath}${clientId}/forms/new-capital-lease-form/${formId}/docs/${docId}`
		);
	}
	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}`).pipe();
	}

	getAllForms(clientId: string, request: any): Observable<any[]> {
		return this.httpClient.post<any[]>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/all`, request).pipe();
	}
	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		const url = `${
			this.urlPath
		}${clientId}/forms/new-capital-lease-form/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<NewCapitalLeaseFormView> {
		return this.httpClient
			.post<NewCapitalLeaseFormView>(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	exportFormPayments(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/forms/new-capital-lease-form/${formId}/export-new-capital-lease-form`, {
				responseType: 'blob',
			})
			.pipe();
	}

	getAllActiveFormsFiscalYears(clientId: string): Observable<any> {
		return this.httpClient.get(`${this.urlPath}${clientId}/forms/new-capital-lease-form/all-fiscal-years`).pipe();
	}

	getAllFormsByFiscalYearAndApplicableMonth(
		clientId: string,
		fiscalYear: number,
		applicableMonth: number,
		leaseType: string
	): Observable<any> {
		return this.httpClient
			.get(
				`${this.urlPath}${clientId}/forms/new-capital-lease-form/forms-fiscalYear-applicableMonth/${fiscalYear}/${applicableMonth}/${leaseType}`
			)
			.pipe();
	}
}
