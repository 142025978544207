import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { Vendor } from 'app/models/Vendor';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { environment } from 'environments/environment';
import { VendorPaymentValidation } from 'app/models/form/VendorPaymentValidation';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class VendorFormService {
	private readonly urlPath = 'vendor';

	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices
	) {}

	createForm(clientId: string, request: any, prefix: string): Observable<Vendor> {
		return this.httpClient
			.post<Vendor>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`,
				this.setupRequest(request, prefix)
			)
			.pipe();
	}

	private setupRequest(request: any, prefix: string) {
		const formData: FormData = new FormData();
		formData.append('vendor W9', request.vendorW9File);
		formData.append('voided Check', request.voidedCheckFile);
		formData.append('model', JSON.stringify(request));
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `additional file -${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, prefix, request);

		return formData;
	}

	updateForm(clientId: string, formId: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`,
				this.setupRequest(request, prefix)
			)
			.pipe();
	}

	removeDocument(clientId: string, formId: string, documentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/document/${documentId}`)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<Vendor> {
		return this.httpClient.get<Vendor>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<Vendor> {
		return this.httpClient
			.post<Vendor>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<Vendor> {
		return this.httpClient
			.post<Vendor>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<Vendor> {
		return this.httpClient
			.post<Vendor>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	exportToPdf(clientId: string, formId: string): any {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}

	getVendors(clientId: string, filter: string): Observable<Vendor[]> {
		return this.httpClient
			.get<Vendor[]>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/GetAllVendors/${filter}`)
			.pipe();
	}

	exportVendors(clientId: string, filter: string) {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/export/${filter}`, {
				responseType: 'blob',
			})
			.pipe();
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	validatePayment(clientId: string, vendorId: string): Observable<VendorPaymentValidation> {
		return this.httpClient
			.get<VendorPaymentValidation>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${vendorId}/validate-payment`
			)
			.pipe();
	}
	importVendors(clientId: string, data: any): Observable<HttpEvent<any>> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/import-vendor`, data)
			.pipe();
	}
}
