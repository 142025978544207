import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormCommentView } from 'app/models/form/FormCommentView';
import { environment } from 'environments/environment';
import { FormOptions } from 'app/models/dashboard/FormStatus';
import { FormUtilityService } from 'app/services/form/form.utility.service';

@Injectable({
	providedIn: 'root',
})
export class FormCommentService {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly formUtilityService: FormUtilityService
	) {}

	sendComment(
		clientId: string,
		formId: string,
		item: FormCommentView,
		file: File,
		commentType?: string | null,
		serviceType?: FormOptions | null
	): Observable<FormCommentView> {
		if (commentType) {
			return this.commentTypeService(clientId, formId, item, file, commentType);
		} else if (serviceType) {
			return this.commentFormTypeService(clientId, formId, item, file, serviceType);
		}
	}

	commentFormTypeService(
		clientId: string,
		formId: string,
		item: FormCommentView,
		file: File,
		serviceType?: FormOptions | null
	) {
		let service = this.formUtilityService.getService(serviceType);
		if (service) {
			return service.sendComment(clientId, formId, item, file).pipe();
		}
	}

	commentTypeService(clientId: string, formId: string, item: FormCommentView, file: File, commentType?: string | null) {
		let url = '';
		switch (commentType) {
			case 'fieldwork':
				url = `${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/comments`;
				break;
			case 'ye-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/comments`;
				break;
			case 'hr-support':
				url = `${environment.apiUrl}forms/hr-support/${formId}/comments`;
				break;
			case 'request-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/request-form/${formId}/comments`;
				break;
			case 'support-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/support-request/${formId}/comments`;
				break;
			case 'task-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}/comments`;
				break;
			case 'purchasing':
				url = `${environment.apiUrl}forms/clients/${clientId}/purchasing/${formId}/comments`;
				break;
			case 'change-order':
				url = `${environment.apiUrl}forms/clients/${clientId}/change-order/${formId}/comments`;
				break;
			case 'ap-invoice':
				url = `${environment.apiUrl}forms/clients/${clientId}/ap-invoice/${formId}/comments`;
				break;
			case 'check-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/check-request/${formId}/comments`;
				break;
			case 'p-card':
				url = `${environment.apiUrl}forms/clients/${clientId}/p-card/${formId}/comments`;
				break;
			case 'vendor-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/vendor/${formId}/comments`;
				break;
			case 'invoice-approval-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/invoice-approval/${formId}/comments`;
				break;
			case 'timesheet':
				url = `${environment.apiUrl}forms/clients/${clientId}/timesheet/${formId}/comments`;
				break;
			case 'monthly-timesheet':
				url = `${environment.apiUrl}forms/clients/${clientId}/monthly-timesheet/${formId}/comments`;
				break;
			case 'new-project':
				url = `${environment.apiUrl}forms/clients/${clientId}/new-project/${formId}/comments`;
				break;
			case 'cc-approval':
				url = `${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}/comments`;
				break;
			case 'bid-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/bid/${formId}/comments`;
				break;
			case 'license-form':
				url = `${environment.apiUrl}licensing/clients/${clientId}/license/${formId}/comments`;
				break;
			case 'capital-addition-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/additions/${formId}/comments`;
				break;
			case 'capital-disposal-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/disposals/${formId}/comments`;
				break;
			case 'capital-transfer-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/transfer/${formId}/comments`;
				break;
			case 'receipt-verification':
				url = `${environment.apiUrl}forms/clients/${clientId}/ReceiptVerification/${formId}/comments`;
				break;
			case 'cashiering-report':
				url = `${environment.apiUrl}forms/clients/${clientId}/cashiering-report/${formId}/comments`;
				break;
			case 'journal-form':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/journal-entry/${formId}/comments`;
				break;
			case 'project-mgt':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-mgt/${formId}/comments`;
				break;
			case 'lease-evaluation':
				url = `${environment.apiUrl}forms/clients/${clientId}/lease-evaluation/${formId}/comments`;
				break;
			case 'department-addition-form':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/department-addition/${formId}/comments`;
				break;
			case 'fund-addition-form':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/fund-addition/${formId}/comments`;
				break;
			case 'funding-increase-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-fund-increase-form/${formId}/comments`;
				break;
			case 'payment-approval-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/payment-approval-form/${formId}/comments`;
				break;
			case 'contract-approval-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/contract-approval/${formId}/comments`;
				break;
			case 'gl-account':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/accounts/${formId}/comments`;
				break;
			case 'project-close-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-close-form/${formId}/comments`;
				break;
			case 'application-form':
				url = `${environment.apiUrl}water-utility/clients/${clientId}/application/${formId}/comments`;
				break;
			case 'po-rollover-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/po-rollover-form/${formId}/comments`;
				break;
			case 'project-open-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-open-form/${formId}/comments`;
				break;
			case 'customer-invoice-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-invoice-form/${formId}/comments`;
				break;
			case 'customer-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-form/${formId}/comments`;
				break;
			case 'quick-pay':
				url = `${environment.apiUrl}forms/clients/${clientId}/quick-pay/${formId}/comments`;
				break;
			case 'quick-invoice-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/quick-invoice-form/${formId}/comments`;
			case 'work-order-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/comments`;
				break;
			case 'project-grant-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-form/${formId}/comments`;
				break;
			case 'project-grant-task':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-task/${formId}/comments`;
				break;
			case 'bank-reconciliation':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/bank-rec/${formId}/comments`;
				break;
			case 'project-grant-close-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-close-form/${formId}/comments`;
				break;
			case 'project-grant-reopen-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-reopen-form/${formId}/comments`;
				break;
			case 'open-close-period':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/open-close-period/${formId}/comments`;
				break;
			case 'depreciation-schedule-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/depreciation-schedule-form/${formId}/comments`;
				break;
			case 'employee-action-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/employee-action-form/${formId}/comments`;
				break;
			case 'void-reissue-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/void-reissue-form/${formId}/comments`;
				break;
			case 'project-grant-form-status':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-form/${formId}/status-comments`;
				break;
			case 'project-grant-form-other':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-form/${formId}/other-comments`;
				break;
			case 'budget-amendment':
				url = `${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}/comments`;
				break;
			case 'customer-refund-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/customer-refund-form/${formId}/comments`;
				break;
			case 'job-application':
				url = `${environment.apiUrl}hris/clients/${clientId}/job-application-workflow/${formId}/comments`;
				break;
			case 'time-off':
				url = `${environment.apiUrl}hris/clients/${clientId}/time-off/${formId}/comments`;
				break;
			case 'weeky-timesheet':
				url = `${environment.apiUrl}hris/clients/${clientId}/timesheet/${formId}/comments`;
				break;
			case 'performance-evaluation':
				url = `${environment.apiUrl}hris/clients/${clientId}/performance-evaluation/${formId}/comments`;
				break;
			case 'benefit-enrolment-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/benefit-enrolment-form/${formId}/comments`;
				break;
			case 'cash-receipt-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/cash-receipt-form/${formId}/comments`;
				break;
			case 'credit-card-report':
				url = `${environment.apiUrl}forms/clients/${clientId}/credit-card-report/${formId}/comments`;
				break;
			case 'individual-payee-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/individual-payee-form/${formId}/comments`;
				break;
			case 'individual-payment-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/individual-payment-form/${formId}/comments`;
				break;
			case 'create-invoice-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/create-invoice-form/${formId}/comments`;
				break;
			case 'receiving-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/receiving-form/${formId}/comments`;
				break;
			case 'usage-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/usage-form/${formId}/comments`;
				break;
			case 'implementation-billing-ar':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation/${formId}/comments`;
				break;
			case 'implementation-general-ledger':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-general-ledger/${formId}/comments`;
				break;
			case 'implementation-purchasing':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-purchasing/${formId}/comments`;
				break;
			case 'leave-balance-adjustment':
				url = `${environment.apiUrl}hris/clients/${clientId}/leave-balance/${formId}/comments`;
				break;
			case 'implementation-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-payment/${formId}/comments`;
				break;
			case 'new-hire-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/employee/${formId}/comments`;
				break;
			case 'timesheet-approval-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/timesheet-approval-form/${formId}/comments`;
				break;
			case 'customer-invoice-adjustment-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-invoice-adjustment-form/${formId}/comments`;
				break;
			case 'implementation-hr':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-hr/${formId}/comments`;
				break;
			case 'complainant-form':
				url = `${environment.apiUrl}code-enforcement/clients/${clientId}/complainant-form/${formId}/comments`;
				break;
			case 'electronic-ach-report':
				url = `${environment.apiUrl}forms/clients/${clientId}/electronic-ach-report/${formId}/comments`;
				break;
			case 'utility-application-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/application/${formId}/comments`;
				break;
			case 'usage-adjustment-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/usage-adjustment-form/${formId}/comments`;
				break;
			case 'fee-adjustment-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/fee-adjustment-form/${formId}/comments`;
				break;
			case 'fiserv-refund-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/fiserv-refund-form/${formId}/comments`;
				break;
			case 'tuition-reimbursement':
				url = `${environment.apiUrl}forms/clients/${clientId}/tuition-reimbursement/${formId}/comments`;
				break;
			case 'tuition-reimbursement-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/tuition-reimbursement-payment/${formId}/comments`;
				break;
			case 'location-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/location/${formId}/comments`;
				break;
			case 'item-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/item/${formId}/comments`;
				break;
			case 'new-customer-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/customer-form/${formId}/comments`;
				break;
			case 'new-property-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/property-form/${formId}/comments`;
				break;
			case 'new-customer-account-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/customer-account-form/${formId}/comments`;
				break;
			case 'manual-meter-read-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/manual-meter-read-form/${formId}/comments`;
				break;
			case 'meter-turn-on-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/meter-turn-on-form/${formId}/comments`;
				break;
			case 'meter-turn-off-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/meter-turn-off-form/${formId}/comments`;
				break;
			case 'mass-pay-rate-change-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/forms/mass-pay-rate-change-form/${formId}/comments`;
				break;
			case 'adjustment-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/adjustment-form/${formId}/comments`;
				break;
			case 'implementation-payroll':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-payroll/${formId}/comments`;
				break;
			case 'payment-plan-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/payment-plan-form/${formId}/comments`;
				break;
			case 'meter-change-out-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/meter-change-out-form/${formId}/comments`;
				break;
			case 'asset':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/lookup/${formId}/comments`;
				break;
			case 'other-bank-reconciliation':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/other-bank-rec/${formId}/comments`;
				break;
			case 'capital-work-order-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/work-order/${formId}/comments`;
				break;
			case 'special-event-permit-application-form':
				url = `${environment.apiUrl}recreation/clients/${clientId}/forms/special-event-permit-application-form/${formId}/comments`;
				break;
			case 'customer-credit-memos':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-credit-memo-form/${formId}/comments`;
				break;
			case 'vendor-credit-memos':
				url = `${environment.apiUrl}forms/clients/${clientId}/vendor-credit-memo-form/${formId}/comments`;
				break;
			case 'other-tuition-reimbursement-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/other-tuition-reimbursement-payment/${formId}/comments`;
				break;
			case 'other-tuition-reimbursement':
				url = `${environment.apiUrl}forms/clients/${clientId}/other-tuition-reimbursement/${formId}/comments`;
				break;
			case 'restitution-payment-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/restitution-payment-form/${formId}/comments`;
				break;
			case 'customer-feedback-survey':
				url = `${environment.apiUrl}forms/clients/${clientId}/feedback-survey/${formId}/comments`;
				break;
			case 'field-payment-request-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/field-payment-request-form/${formId}/comments`;
				break;
			case 'initial-purchase-request-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/initial-purchase-request-form/${formId}/comments`;
				break;
			case 'daily-fees-import-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/daily-fees-import-form/${formId}/comments`;
				break;
		}

		const formData: FormData = new FormData();
		if (file !== null && file !== undefined) {
			formData.append(file.name, file);
		}

		formData.append('model', JSON.stringify(item));

		return this.httpClient.post<FormCommentView>(url, formData).pipe();
	}
}
