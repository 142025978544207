// @ts-ignore
import packageInfo from '../../package.json';

export const environment = {
	production: true,
	apiUrl: 'https://portal.aclarian.com/api/',
	reportingServiceUrl: 'https://reportwriter-prod.aclarian.com/api/',
	baseUrl: 'https://portal.aclarian.com',
	appName: 'Workflow Admin',
	aclarianId: '16731dca-45e0-4cc5-a9f5-2f0a759db4af',
	madeiraBeachId: 'dc73e425-5a7b-4478-92b5-ccfd41a8fb32',
	dadeCityId: '93894114-d5bb-4120-9f1d-1ad141d8360c',
	gladesCounty: '7e6a934b-2bd6-4e3b-975d-b5bcba301218',
	mooreHaven: '69356a1b-9e4b-495d-a7e7-d9f5a5ab139a',
	gladesBOCC: '2af34bfe-fa30-4099-8c6d-b098ca72f4f8',
	frameworkGroupLLC: 'cfb1b93d-8bf9-492a-bc9d-5817de48a3e2',
	kennethCity: '33da858e-6e1c-4297-b6ee-11b8277b2f2d',
	manateeCounty: '5f62b660-f552-40ba-971b-bd32dd18403a',
	oldsmarCityId: '70338585-76b3-4d0b-839a-939c7779f592',
	tampaBayWater: '9c5729be-b7b8-4df4-97af-beb45a810001',
	lakeHelenId: '8efe7f03-b352-46ec-b49e-a318fbe18e4c',
	opalockaId: '2e3f4467-4925-4138-a8f7-265a034b7be4',
	metroPlanOrlando: '81d51a8e-5099-42d6-9ee4-98c100b53a0d',
	hillboroughCountyTaxCollector: '9d8f6ee7-edbf-4b79-9caf-094c926343a1',
	osceolaClerk: '34792ab0-0628-41d8-aacf-3dd2f14519cb',
	sampleCity: 'ec0eff1e-9433-4058-a8bf-eb3b5d011264',
	westCoastInlandNavigationDistrict: '6f3772a3-4f83-4bd0-8078-c128bac5234d',
	osceolaTaxCollector: '32f61b36-c01c-46af-8a5f-5de960c1cb4c',
	cityOfGroveland: '8025e4e7-dccd-4380-ac32-47dcfc2a3c08',
	brevardCountyTaxCollector: 'eee932f0-60db-4d8f-989d-377fb30581a9',
	palmBeachTransportationPlanningAgency: '91c8341f-cf71-4d06-817b-6e2b19fc63f8',
	cityOfMargate: '6bef6c64-cd38-45dc-96a4-e69e0afa0fb7',
	paSchools: 'a14e0ed4-d36c-4cdd-a1e3-1b897a06260e',
	cityOfKosciusko: '8cc27400-b64d-4463-b0aa-addb4eef450c',
	name: 'PROD',
	azureAiSpeechServiceSubscriptionKey: 'a195c54ef04241daa7ed28171cd3a4a2',
	azureAiSpeechServiceRegion: 'eastus',
	datadogClientToken: 'pub51fb2ae5d7f9fec0c7fea38996717672',
	datadogApplicationId: '5c6dcdb1-271e-41b4-9a71-39709cbdda76',
	datadogService: 'aclarian_portal_prod',
	version: packageInfo.version,
	msEntraClientId: '8e033640-95d7-412f-993e-415582da794f',
	msEntraLoginScope: 'api://1ab4e4be-9d8d-4473-9112-56c5004b70d2/User.Login',
};
