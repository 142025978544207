import { Injectable } from '@angular/core';
import { EDynamicFormInputType } from 'app/models/form/DynamicFormView';

@Injectable({
	providedIn: 'root',
})
export class DynamicFormUtilityServices {
	constructor() {}

	//Append Addtional fields Files
	appendDocuments(formData: FormData, prefix: string, request: any) {
		for (let i = 0; i < request.additionalField.dynamicFormConfigurations.length; i++) {
			const configuration = request.additionalField.dynamicFormConfigurations[i];

			const isInputTypeFile = configuration.dynamicFormInputTypeId == EDynamicFormInputType.FileInput;
			if (isInputTypeFile) {
				formData.append(configuration.tempLabel.slice(prefix.length), request[configuration.tempLabel]);
			}
		}
	}
	appendMultipleTypeDocuments(formData: FormData, prefix: string, request: any) {
		for (let i = 0; i < request.additionalField.dynamicFormMultipleTypeConfigurations.length; i++) {
			const configurations = request.additionalField.dynamicFormMultipleTypeConfigurations[i];

			for (let i = 0; i < configurations.dynamicFormConfigurations.length; i++) {
				const configuration = configurations.dynamicFormConfigurations[i];

				const isInputTypeFile = configuration.dynamicFormInputTypeId == EDynamicFormInputType.FileInput;
				if (isInputTypeFile) {
					formData.append(configuration.tempLabel.slice(prefix.length), request[configuration.tempLabel]);
				}
			}
		}
	}
}
