import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { HttpClient } from '@angular/common/http';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class CustomerCreditMemoService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}miscellaneous-billing/clients/$$clientId$$/customer-credit-memo-form`,
			httpClient,
			utilityService
		);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	emailMemo(clientId: string, id: string, isCreator: boolean): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${id}/email-memo/${isCreator}`, {}).pipe();
	}
	generateMemo(clientId: string, id: string): Observable<any> {
		let url = `${this.getUrl(clientId)}/${id}/generate-memo`;
		return this.httpClient.get<any>(url).pipe();
	}
	changeStatus(clientId: string, id: string) {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${id}/change-status`).pipe();
	}
	getAllMemos(clientId: string, type: any) {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${type}/search`).pipe();
	}
	getAllActiveMemosForCustomer(clientId: string, customerId: string) {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${customerId}/get-memo-for-customer`).pipe();
	}
}
