import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { DptExpenseBudgetView } from 'app/models/form/DptExpenseBudgetView';
import { map } from 'rxjs/operators';
import { UtilityService } from 'app/services/utility.service';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class DptExpenseBudgetServices extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/expense-budget`, httpClient, utilityService);
	}

	getBudgetForDepartment(clientId: string, departmentId: number, fiscalYear: number): Observable<DptExpenseBudgetView> {
		return this.httpClient
			.get<DptExpenseBudgetView>(`${this.getUrl(clientId)}/departments/${departmentId}?fiscalYear=${fiscalYear}`)
			.pipe(
				map((data) => {
					if (data.id === '00000000-0000-0000-0000-000000000000') {
						data.id = '00000000-0000-0000-0000-000000000000';
					}
					return data;
				})
			);
	}

	getAvailableBudgets(clientId: string, fiscalYear: number): Observable<DptExpenseBudgetView[]> {
		return this.httpClient.get<DptExpenseBudgetView[]>(`${this.getUrl(clientId)}/available?fiscalYear=${fiscalYear}`);
	}
	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.getUrl(clientId)}/${id}`).pipe();
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	export(clientId: string, id: string) {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/${id}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}
	isExpenseBudgetFormSubmittedForDepartment(clientId: string, departmentId: any, fiscalYear: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/IsExpenseBudgetFormSubmittedForDepartment/${departmentId}/year/${fiscalYear}`)
			.pipe();
	}
	isExpenseBudgetFormCompletedForDepartment(clientId: string, departmentId: any, fiscalYear: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/IsExpenseBudgetFormCompletedForDepartment/${departmentId}/year/${fiscalYear}`)
			.pipe();
	}
	revertExpenseBudgetForm(clientId: string, id: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${id}/RevertExpenseBudgetForm`, {}).pipe();
	}
	exportNextYearExpensesReport(clientId: string, departmentId: any, fiscalYear: number): Observable<any> {
		return this.httpClient
			.post(
				`${this.getUrl(clientId)}/export-next-year-expenses-report?departmentId=${departmentId}&fiscalYear=${fiscalYear}`,
				null,
				{ responseType: 'blob' }
			)
			.pipe();
	}
}
