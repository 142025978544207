import { Injectable } from '@angular/core';
import { BaseFormService } from '../BaseFormService';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { MGOFeesDataRequest } from 'app/models/form/DailyFeesImportFormView';

@Injectable({
	providedIn: 'root',
})
export class DailyFeesImportFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}cashiering/clients/$$clientId$$/forms/daily-fees-import-form`,
			httpClient,
			utilityService
		);
	}
	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	getMGOFeesData(clientId: string, params: MGOFeesDataRequest): Observable<any> {
		const url = `${this.getUrl(clientId)}/get-mgo-data`;
		return this.httpClient.get<any>(url, { params: { ...params } }).pipe();
	}
	checkExistingForm(
		clientId: string,
		formId: any,
		projectType: any,
		transactionType: any,
		startDate: any,
		endDate: any
	): Observable<any> {
		const url = `${this.getUrl(clientId)}/check-existing-form`;
		const params = {
			formId,
			projectType,
			transactionType,
			startDate,
			endDate,
		};
		return this.httpClient.get<any>(url, { params }).pipe();
	}
	getDailyFessReport(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any[]>(`${this.getUrl(clientId)}/report`, request).pipe();
	}
}
