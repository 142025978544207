import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class TimeOffService {
	private readonly urlPath = `${environment.apiUrl}hris/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/time-off`, request).pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.urlPath}${clientId}/time-off/${id}`, request).pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/time-off/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/time-off/${id}/decline`, rejectionReason).pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/time-off/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/time-off/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/time-off/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/time-off/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/time-off/${formId}/docs/${docId}`);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/time-off/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/time-off/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getAll(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/time-off?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}

	getAll2(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/time-off/GetAll2?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}

	getAllWorkflowForms(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/time-off/workflow-forms?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}
	updateTimeOffRequestForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.urlPath}${clientId}/time-off/${id}/update`, request).pipe();
	}
	getAllApprovedTimeOffsForEmployee(
		clientId: string,
		employeeId: string,
		leaveTypeId: any,
		payPeriodStartDate: any,
		payPeriodEndDate: any
	): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/time-off/all-approved-timeoffs?employeeId=${employeeId}&leaveTypeId=${leaveTypeId}&payPeriodStartDate=${payPeriodStartDate}&payPeriodEndDate=${payPeriodEndDate}`
			)
			.pipe();
	}
	getTimeOffRequestsReport(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/time-off/time-off-requests-report`, request).pipe();
	}
}
