import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { environment } from '../../../environments/environment';
import { CashieringReportView } from 'app/models/form/CashieringReportView';

@Injectable({
	providedIn: 'root',
})
export class CashieringReportService {
	private readonly urlPath = 'cashiering-report';

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: CashieringReportView): Observable<CashieringReportView> {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<CashieringReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, formData)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<CashieringReportView> {
		return this.httpClient
			.get<CashieringReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<CashieringReportView> {
		return this.httpClient
			.post<CashieringReportView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<CashieringReportView> {
		return this.httpClient
			.post<CashieringReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CashieringReportView> {
		return this.httpClient
			.post<CashieringReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		const formData = new FormData();
		formData.append('model', JSON.stringify(request));
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`, formData)
			.pipe();
	}
	updateCashPostingDate(clientId: string, id: any, cashPostingDate: Date): Observable<any> {
		const body = { effectiveDate: cashPostingDate };
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/updateCashPostingDate/${id}`, body)
			.pipe();
	}
	getCashieringReportListing(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any[]>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/report-listing`, request)
			.pipe();
	}
}
