import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactViewModel } from 'app/models/business-license/ContactViewModel';
import { CaseViewModel } from 'app/models/code-enforcement/CaseViewModel';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class ComplainantFormService extends BaseFormService {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}code-enforcement/clients/$$clientId$$/complainant-form`, httpClient, utilityService);
	}

	search(clientId: string): Observable<CaseViewModel[]> {
		return this.httpClient.post<CaseViewModel[]>(`${this.getUrl(clientId)}/search`, null).pipe();
	}

	getViolationTypes(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/violationTypes`).pipe();
	}

	getComplaintTypes(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/complaintTypes`).pipe();
	}

	disable(clientId: string, formId: string): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${formId}`).pipe();
	}

	updateContact(clientId: string, licenseId: string, id: number, model: any): Observable<ContactViewModel[]> {
		return this.httpClient
			.post<ContactViewModel[]>(`${this.getUrl(clientId)}/${licenseId}/contacts/${id}`, model)
			.pipe();
	}

	courtesyNotice(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/courtesynotice/${id}`, {
				headers: this.headers,
			})
			.pipe();
	}

	noticeOfViolation(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/noticeofviolation/${id}`, {
				headers: this.headers,
			})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	exportComplaintsToExcel(clientId: string, isActive) {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/export?isActive=${isActive}`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
