import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { environment } from 'environments/environment';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';

@Injectable({
	providedIn: 'root',
})
export class BankRecService {
	private readonly urlPath = `${environment.apiUrl}general-ledger/clients/`;
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/bank-rec`, request).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}`, request).pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}/decline`, rejectionReason).pipe();
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/forms/bank-rec/${formId}/docs/${docId}`);
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/forms/bank-rec/users/${userId}?${this.utilityService.objectToQueryString(
			search
		)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/forms/bank-rec/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getUnclearedRecords(
		clientId: string,
		month: number,
		fiscalYear: number,
		isActive: boolean,
		source: string = ''
	): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/forms/bank-rec/unclear?month=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}&source=${source}`
			)
			.pipe();
	}

	getBankRecords(clientId: string, month: number, fiscalYear: number, isActive: boolean, source: string = '') {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/forms/bank-rec?fiscalMonth=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}&source=${source}`
			)
			.pipe();
	}

	createMatch(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/create-match`, request).pipe();
	}

	removeMatch(clientId: string, id: number): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/forms/bank-rec/${id}/remove-match`).pipe();
	}

	getAvailableYears(clientId: string): Observable<number[]> {
		let url = `${this.urlPath}${clientId}/forms/bank-rec/available-years`;
		return this.httpClient.get<number[]>(url, { headers: this.headers }).pipe();
	}

	getAvailableMonths(clientId: string, year: number): Observable<number[]> {
		let url = `${this.urlPath}${clientId}/forms/bank-rec/${year}/available-months`;
		return this.httpClient.get<number[]>(url, { headers: this.headers }).pipe();
	}

	getBankRecFormSetup(clientId: string, year: number, month: number): Observable<any> {
		let url = `${this.urlPath}${clientId}/forms/bank-rec/${year}/${month}/setup`;
		return this.httpClient.get<any>(url, { headers: this.headers }).pipe();
	}

	exportUnclearTransactionToExcel(clientId: string, month: number, fiscalYear: number, isActive: boolean) {
		return this.httpClient
			.get(
				`${this.urlPath}${clientId}/forms/bank-rec/unclear-transactions/export?month=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}`,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}
	exportPendingMatchedTransactionToExcel(clientId: string) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/forms/bank-rec/pending-matched-transactions/export`, {
				responseType: 'blob',
			})
			.pipe();
	}

	exportUnclearBankTransactionToExcel(
		clientId: string,
		month: number,
		fiscalYear: number,
		isActive: boolean,
		configurationId: any
	) {
		return this.httpClient
			.get(
				`${this.urlPath}${clientId}/forms/bank-rec/unclear-bank-transactions/export?month=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}&configurationId=${configurationId}`,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}

	uploadUnclearBankTransactionFromExcel(clientId: string, file: File, configurationId?: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', file);

		let params = new HttpParams();
		if (configurationId !== null && configurationId !== undefined) {
			params = params.append('configurationId', configurationId);
		}

		const req = new HttpRequest(
			'POST',
			`${this.urlPath}${clientId}/forms/bank-rec/unclear-bank-transactions/upload-file`,
			formData,
			{
				params: params,
				reportProgress: false,
				responseType: 'json',
			}
		);

		return this.httpClient.request(req).pipe();
	}

	downloadUnclearTransactionSampleFile(clientId: string) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/forms/bank-rec/unclear-bank-transactions/download-sample-file`, {
				responseType: 'blob',
			})
			.pipe();
	}
	getConfigurations(clientId: any): Observable<any> {
		return this.httpClient.get(`${this.urlPath}${clientId}/forms/bank-rec/bank-rec-import-config`).pipe();
	}
	moveToClearTransaction(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/move-to-clear-transaction`, request)
			.pipe();
	}
	moveToUnclearTransaction(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/bank-rec/move-to-unclear-transaction`, request)
			.pipe();
	}
}
