import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RefreshTokenSubjectService {
	private loginSuccessSubject = new BehaviorSubject<boolean>(false);
	loginSuccess = this.loginSuccessSubject.asObservable();

	triggerLoginSuccess() {
		this.loginSuccessSubject.next(true);
	}
}
