import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { UtilityService } from 'app/services/utility.service';
import { environment } from 'environments/environment';
import { EmployeePayrollHistory } from 'app/models/hris/EmployeePayrollHistory';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EmployeeServices {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });
	private readonly urlPath = `${environment.apiUrl}hris/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.directDeposits.length; i++) {
			const name = `attachment-${i + 1}`;
			if (!request.directDeposits[i].voidedCheck) continue;
			formData.append(name, request.directDeposits[i].voidedCheck);
			request.directDeposits[i].voidedCheck = null;
		}
		formData.append('model', JSON.stringify(request));
		return formData;
	}

	getAllEmployees(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/all`).pipe();
	}

	getAllEmployeesForUser(clientId: string, claimId: string, departmentId: number = 0): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/department/${departmentId}/${claimId}`).pipe();
	}

	getEmployee({ clientId, employeeId }: { clientId: string; employeeId: string }): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}`).pipe();
	}

	getEmergencyContact(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/emergency-contact`, { headers: this.headers })
			.pipe();
	}

	getEmployeeAddresses(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-addresses`, { headers: this.headers })
			.pipe();
	}

	updateBasicInfo(clientId: string, employeeId: string, employee: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/update-basic-info`, employee)
			.pipe();
	}

	updateEmergencyContact(clientId: string, employeeId: string, contact: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/update-emergency-contact`, contact)
			.pipe();
	}

	manageEmployeeAddresses(clientId: string, employeeId: string, address: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/manage-employee-addresses`, address)
			.pipe();
	}

	addEmployeeBeneficiary(clientId: string, employeeId: string, beneficiary: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/add-employee-beneficiary`, beneficiary)
			.pipe();
	}

	updateDirectDeposit(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/direct-deposit`, this.setupRequest(model))
			.pipe();
	}

	getEmployeeBeneficiaries(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-beneficiaries`, { headers: this.headers })
			.pipe();
	}

	getDirectDepositInfo(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/direct-deposit`, { headers: this.headers })
			.pipe();
	}

	createEmployee(clientId: string, employee: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/create-employee`, this.setupRequest(employee))
			.pipe();
	}

	updateEmployee(clientId: string, employee: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/update-employee`, this.setupRequest(employee))
			.pipe();
	}

	getLogedInEmployeeId(clientId: any): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/get-logedIn-employeeId`).pipe();
	}

	getUserEmployeeId(clientId: any, userId: any): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/get-logedIn-employeeId?userId=${userId}`)
			.pipe();
	}

	updateEmployeeAllocation(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/update-employee-allocation`, model)
			.pipe();
	}

	updateEmployeeAllocationAccounts(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/update-employee-allocation-accounts`, model)
			.pipe();
	}

	getEmployeeAllocationDetail(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-allocation`).pipe();
	}

	getCurrentJob(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-current-job`, { headers: this.headers })
			.pipe();
	}

	getJobHistory(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-job-history`, { headers: this.headers })
			.pipe();
	}

	getLeaveHistory(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-leave-history`, { headers: this.headers })
			.pipe();
	}

	getPayHistory(clientId: string, employeeId: string): Observable<EmployeePayrollHistory[]> {
		return this.httpClient
			.get<EmployeePayrollHistory[]>(`${this.urlPath}${clientId}/employee/${employeeId}/get-pay-history`, {
				headers: this.headers,
			})
			.pipe();
	}

	saveEmployeeJob(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/add-employee-job`, model)
			.pipe();
	}

	UpdateEmployeeJob(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/update-employee-job`, model)
			.pipe();
	}

	getEmployeeElections(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-elections`, { headers: this.headers })
			.pipe();
	}
	getEmployeeAllElections(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-all-elections`, {
				headers: this.headers,
			})
			.pipe();
	}
	getTaxInfo(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-tax-info`, { headers: this.headers })
			.pipe();
	}

	saveTaxInfo(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/save-tax-info`, model).pipe();
	}

	getEmployeeRelations(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-relations`, { headers: this.headers })
			.pipe();
	}

	getHrReport(clientId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/get-hr-report`, { headers: this.headers })
			.pipe();
	}

	getEmployeeNotes(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-notes`, { headers: this.headers })
			.pipe();
	}

	saveEmployeeNote(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/update-employee-notes`, model)
			.pipe();
	}
	employeeLoginExist(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/employee-login-exist`).pipe();
	}
	generateLogin(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/generate-login`).pipe();
	}
	linkEmployeeToUser(clientId: string, employeeId: string, userId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/${userId}/link-employee-to-user`)
			.pipe();
	}
	createPortalLogin(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/create-login`, {}).pipe();
	}

	removeDirectDepositInfo(clientId: string, employeeId: string, directDepositId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.urlPath}${clientId}/employee/${employeeId}/direct-deposit/${directDepositId}`)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/${formId}/decline`, rejectionReason).pipe();
	}

	getForm(clientId: string, formId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/form/${formId}`).pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/employee/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/${formId}/review`, {}).pipe();
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/employee/${id}`).pipe();
	}

	getPayHistoryPDF(clientId: string, employeeId: string, payHistoryId: any) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/employee/${employeeId}/${payHistoryId}/get-pay-history-pdf`, {
				headers: this.headers,
				responseType: 'blob',
			})
			.pipe();
	}

	getAllPayHistoryPDF(clientId: string, payHistoryId: string) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/employee/get-pay-history-pdf-clubbed/${payHistoryId}`, {
				headers: this.headers,
				responseType: 'blob',
			})
			.pipe();
	}

	getPayPeriodHistory(clientId: string, employeeId: string, payPeriod: any, fiscalYear: string): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/employee/${employeeId}/${payPeriod}/${fiscalYear}/get-leave-balances-history`,
				{ headers: this.headers }
			)
			.pipe();
	}
	getAllEmployeesForAllDepartments(clientId: string, claimId: string, departments: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/department/${claimId}`, departments).pipe();
	}

	getAllTerminatedEmployees(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/terminated/all`).pipe();
	}

	getAllEmployeesWithJobsForAllDepartments(clientId: string, departments: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/departments/jobs`, departments).pipe();
	}

	removeEmployeeBeneficiary(clientId: string, employeeId: string, beneficiaryId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.urlPath}${clientId}/employee/${employeeId}/delete-employee-beneficiary/${beneficiaryId}`)
			.pipe();
	}
	getAllActiveInActiveEmployees(clientId: string, claimId: string, departmentId: number = 0): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/department/active-inactive/${departmentId}/${claimId}`)
			.pipe();
	}

	addEmployeeDependent(clientId: string, employeeId: string, dependent: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/add-employee-dependent`, dependent)
			.pipe();
	}

	getEmployeeDependents(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/get-employee-dependents`, { headers: this.headers })
			.pipe();
	}

	removeEmployeeDependent(clientId: string, employeeId: string, dependentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.urlPath}${clientId}/employee/${employeeId}/delete-employee-dependent/${dependentId}`)
			.pipe();
	}

	getEmployeeEquipments(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/equipments`, { headers: this.headers })
			.pipe();
	}

	saveEmployeeEquipment(clientId: string, employeeId: string, model: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/equipment`, model).pipe();
	}

	removeEmployeeEquipment(clientId: string, employeeId: string, equipmentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.urlPath}${clientId}/employee/${employeeId}/equipment/${equipmentId}`)
			.pipe();
	}

	getEmployeeTrainingsAndCertifications(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/trainingsAndCertifications`, {
				headers: this.headers,
			})
			.pipe();
	}

	saveEmployeeTrainingAndCertification(clientId: string, employeeId: string, model: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(model));
		formData.append('certificateUploadFile', model.certificateUploadFile);
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/trainingAndCertification`, formData)
			.pipe();
	}

	removeEmployeeTrainingAndCertification(
		clientId: string,
		employeeId: string,
		trainingAndCertificationId: string
	): Observable<any> {
		return this.httpClient
			.delete<any>(
				`${this.urlPath}${clientId}/employee/${employeeId}/trainingAndCertification/${trainingAndCertificationId}`
			)
			.pipe();
	}

	getEmployeeDocuments(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/${employeeId}/documents`, { headers: this.headers })
			.pipe();
	}

	saveEmployeeDocument(clientId: string, employeeId: string, model: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(model));
		formData.append('uploadedDocument', model.uploadedDocument);
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/${employeeId}/document`, formData).pipe();
	}

	removeEmployeeDocument(clientId: string, employeeId: string, documentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.urlPath}${clientId}/employee/${employeeId}/document/${documentId}`)
			.pipe();
	}
	getEmployeePtos(clientId: string, employeeId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee/employee-pto/${employeeId}`).pipe();
	}
	getGLConfigurationReport(clientId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/employee/get-gl-configuration-report`, { headers: this.headers })
			.pipe();
	}

	getEmployeeDetailReport(clientId: string, request?: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/employee-detail-report`, request).pipe();
	}

	getDirectDepositReport(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee/direct-deposit-report`, request).pipe();
	}

	getPayCheckPDF(clientId: string, employeeId: string, payHistoryId: any) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/employee/${employeeId}/${payHistoryId}/get-pay-check-pdf`, {
				headers: this.headers,
				responseType: 'blob',
			})
			.pipe();
	}
	generatePreview(clientId: string, employeeId: string, payPeriod: any, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get(
				`${this.urlPath}${clientId}/timesheet-approval-form/generate-preview?employeeId=${employeeId}&payPeriod=${payPeriod}&startDate=${startDate}&endDate=${endDate}`,
				{
					headers: this.headers,
					responseType: 'blob',
				}
			)
			.pipe();
	}
}
