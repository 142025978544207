import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentDdlSelectComponent } from '../components/administration/ddl/department-ddl-select/department-ddl-select.component';
import { UserDdlSelectComponent } from 'app/components/administration/ddl/user-ddl-select/user-ddl-select.component';
import { ClientDdlSelectComponent } from 'app/components/administration/ddl/client-ddl-select/client-ddl-select.component';
import { VendorCreditMemoDdlSelectComponent } from 'app/components/administration/ddl/vendorcreditmemo-ddl-select/vendorcreditmemo-ddl-select.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatAutocompleteModule,
		MatFormFieldModule,
	],
	declarations: [
		SidebarComponent,
		DepartmentDdlSelectComponent,
		UserDdlSelectComponent,
		ClientDdlSelectComponent,
		VendorCreditMemoDdlSelectComponent,
	],
	exports: [
		SidebarComponent,
		DepartmentDdlSelectComponent,
		UserDdlSelectComponent,
		ClientDdlSelectComponent,
		VendorCreditMemoDdlSelectComponent,
	],
})
export class SidebarModule {}
