import { Injectable } from '@angular/core';
import { BaseFormService } from '../BaseFormService';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class CustomerFeedbackSurveyService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/feedback-survey`, httpClient, utilityService);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	inActiveCustomerFeedbackSurvey(clientId: string, id: any) {
		return this.httpClient.delete(`${this.getUrl(clientId)}/${id}/delete`).pipe();
	}
}
