import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class TimesheetApprovalFormService {
	private readonly urlPath = `${environment.apiUrl}hris/clients/`;
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}
	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/timesheet-approval-form`, this.setupRequest(request))
			.pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/timesheet-approval-form/${id}`, this.setupRequest(request))
			.pipe();
	}
	findForm(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/timesheet-approval-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/timesheet-approval-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			this.urlPath
		}${clientId}/timesheet-approval-form/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/timesheet-approval-form/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/timesheet-approval-form/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/timesheet-approval-form/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/timesheet-approval-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/timesheet-approval-form/${id}`).pipe();
	}
	getTimesheetEntries(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/timesheetFormEntries?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}
	getTimesheetFormValidation(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/timesheetForm/validate?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}
	getAll(
		clientId: string,
		employeeId: string,
		payrollType: number,
		payPeriod: number,
		startDate: any,
		endDate: any
	): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/getAll?employeeId=${employeeId}&payrollType=${payrollType}&payPeriod=${payPeriod}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}

	GetExemptTimesheetEntries(clientId: string, employeeId: string, startDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/getExemptTimesheetEntries?employeeId=${employeeId}&startDate=${startDate}`
			)
			.pipe();
	}

	approveAllForms(
		clientId: string,
		payPeriod: any,
		startDate: any,
		endDate: any,
		forms: any[],
		userId: string = null
	): Observable<any> {
		return this.httpClient
			.post<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/approve-all-forms/${payPeriod}/${startDate}/${endDate}`,
				forms
			)
			.pipe();
	}
	revertTimeSheetApprovalForm(clientId: string, timeSheetApprovalFormId: any): Observable<any> {
		return this.httpClient
			.delete<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/${timeSheetApprovalFormId}/revertTimeSheetApprovalForm`,
				{}
			)
			.pipe();
	}
	removeExistingEntries(clientId: string, timeSheetApprovalFormId: any): Observable<any> {
		return this.httpClient
			.delete<any>(
				`${this.urlPath}${clientId}/timesheet-approval-form/${timeSheetApprovalFormId}/removeExistingEntries`,
				{}
			)
			.pipe();
	}
}
