<section *ngIf="workflow && workflow?.workflowStep != 'Completed' && !workflow?.isCompleted && !workflow?.isDraft">
	<div
		class="row"
		*ngIf="
			(isCurrentApprover || canSkipApprover || isAdmin) &&
			!hideApproval &&
			workflow?.workflowStep != 'Workflow Started' &&
			formId
		"
	>
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-12" [hidden]="hideTitle">
					<h5>Workflow</h5>
				</div>
			</div>
			<div class="row btnss">
				<div class="ml-2" *ngIf="isCurrentApprover || isAdmin">
					<button
						type="button"
						class="btn btn-success btn-round"
						[disabled]="hasAlreadyApproved || isButtonDisabled"
						(click)="approveWorkflow(false)"
					>
						Approve
					</button>
				</div>
				<div class="ml-2" *ngIf="canSkipApprover || isAdmin">
					<button
						type="button"
						class="btn btn-info btn-round"
						style="width: max-content"
						[disabled]="hasAlreadyApproved"
						(click)="approveWorkflow(true)"
					>
						Skip Approval
					</button>
				</div>
				<div class="ml-2" *ngIf="isCurrentApprover || isAdmin">
					<button
						type="button"
						class="btn btn-danger btn-round"
						[hidden]="hideDecline"
						[disabled]="disableRejectionButton || hasAlreadyApproved"
						(click)="rejectWorkflow()"
					>
						Decline
					</button>
				</div>
			</div>
			<div class="row mt-6" [hidden]="!rejectionReasonRequired">
				<div class="ml-2">
					<label>Reason for Rejection</label>
					<textarea
						rows="3"
						class="form-control"
						(change)="rejectionReasonChanged()"
						maxlength="500"
						[class]="!rejectionReasonValid ? 'has-error' : ''"
						[(ngModel)]="rejectionReason"
					>
					</textarea>
				</div>
			</div>
			<div class="row mt-10" *ngIf="(canSendBack && formId) || canSkipApprover">
				<div class="ml-2">
					<button
						type="button"
						[disabled]="disabledCanSendBackBtn"
						(click)="sendBackToAddressComments()"
						class="btn btn-warning btn-round"
					>
						Send Back to Address Comments
					</button>
				</div>
			</div>
		</div>
	</div>
</section>
<section
	*ngIf="
		isAdmin ||
		((!workflow?.workflowId || workflow?.workflowStep == 'Workflow Started' || workflow?.workflowStep == 'DRAFT') &&
			isOwner &&
			formType != '' &&
			formId)
	"
>
	<div class="col-md-2 p-0 mt-2" [hidden]="hideDelete">
		<button type="button" class="btn btn-danger btn-round" (click)="deleteForm()">Delete</button>
	</div>
</section>
