import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';
import {
	ProjectGrantLinkageAccountsView,
	ProjectGrantLinkageFilterView,
	ProjectGrantLinkageView,
} from 'app/models/form/ProjectGrantLinkageFormView';

@Injectable({
	providedIn: 'root',
})
export class ProjectGrantLinkageFormService extends BaseFormService {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}project-grant/clients/$$clientId$$/project-grant-linkage-form`,
			httpClient,
			utilityService
		);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	searchForAccounts(clientId: string, request: ProjectGrantLinkageFilterView): Observable<ProjectGrantLinkageView> {
		return this.httpClient
			.post<ProjectGrantLinkageView>(`${this.getUrl(clientId)}/search-for-accounts`, request)
			.pipe();
	}
}
