import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './security/jwt.interceptor';
import { ErrorInterceptor } from './security/error.interceptor';
import { NgxLoadingModule } from 'ngx-loading';
import { ErrorHandlerService } from './services/error-handler.service';
import { ToastrModule } from 'ngx-toastr';
import { AgGridModule } from 'ag-grid-angular';
import { AnnouncementsModule } from './client/announcements/announcements.module';
import { EffectiveDatePopupComponent } from './components/client/workflow/forms/effective-date-popup/effective-date-popup.component';
import { EditDeleteBtnRenderV2Component } from './components/client/ag-grid/edit-delete-btn-render-v2.component';
import { VendorAutopayValidationComponent } from './components/client/workflow/vendor-autopay-validation/vendor-autopay-validation.component';
import { BudgetToleranceApproversComponent } from './components/client/workflow/forms/budget-tolerance-approvers/budget-tolerance-approvers.component';
import { MultiSelectModule } from 'primeng/multiselect';

import {
	IPublicClientApplication,
	PublicClientApplication,
	InteractionType,
	BrowserCacheLocation,
	LogLevel,
} from '@azure/msal-browser';
import {
	MsalGuard,
	MsalInterceptor,
	MsalBroadcastService,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalGuardConfiguration,
	MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { env } from 'process';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forRoot(AppRoutes, {
			useHash: true,
			enableTracing: false,
		}),
		NgbModule,
		HttpClientModule,
		SidebarModule,
		NavbarModule,
		FooterModule,
		NgxLoadingModule.forRoot({
			backdropBackgroundColour: 'rgba(0,0,0,0.3)',
		}),
		ToastrModule.forRoot(),
		AgGridModule.withComponents([]),
		AnnouncementsModule,
		MultiSelectModule,
		MsalModule,
	],
	declarations: [
		AppComponent,
		AdminLayoutComponent,
		AuthLayoutComponent,
		EffectiveDatePopupComponent,
		EditDeleteBtnRenderV2Component,
		BudgetToleranceApproversComponent,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: ErrorHandler, useClass: ErrorHandlerService },
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
	],
	exports: [],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

export function MSALInstanceFactory(): IPublicClientApplication {
	const clientId = environment.msEntraClientId;
	const redirectUri = environment.baseUrl;

	const msalObj = new PublicClientApplication({
		auth: {
			clientId: clientId,
			authority: 'https://login.microsoftonline.com/common',
			redirectUri: redirectUri,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
		},
	});
	return msalObj;
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: [environment.msEntraLoginScope],
		},
		loginFailedRoute: '/',
	};
}

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}
