import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { LicenseForm } from 'app/models/business-license/License';
import { RefundEntryViewModel } from 'app/models/business-license/RefundEntryViewModel';
import { LicenseFeeViewModel } from 'app/models/business-license/LicenseFeeViewModel';
import { ContactViewModel } from 'app/models/business-license/ContactViewModel';
import { GetLicensesRequestViewModel } from 'app/models/business-license/GetLicensesRequestViewModel';
import { InspectionReportResponseModel } from 'app/models/business-license/InspectionReportResponseModel';
import { BusinessPaymentResponseModel } from '../../models/business-license/BusinessPaymentResponseModel';
import { BusinessPaymentDetailResponseModel } from '../../models/business-license/BusinessPaymentDetailResponseModel';
import { InspectionViewModel } from 'app/models/business-license/InspectionVeiwModel';
import { InvoiceViewModel } from 'app/models/business-license/InvoiceViewModel';
import { RemainingOverpaymentView } from 'app/models/RemainingOverpaymentView';

@Injectable({
	providedIn: 'root',
})
export class LicenseFormService {
	private readonly urlPath = 'license';

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<LicenseForm> {
		return this.httpClient
			.post<LicenseForm>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}`,
				this.setupRequest(request)
			)
			.pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		return formData;
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${formId}`,
				this.setupRequest(request)
			)
			.pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	getForm(id: string, clientId: string): Observable<LicenseForm> {
		return this.httpClient
			.get<LicenseForm>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}licensing/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<LicenseForm> {
		return this.httpClient
			.post<LicenseForm>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<LicenseForm> {
		return this.httpClient
			.post<LicenseForm>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<LicenseForm> {
		return this.httpClient
			.post<LicenseForm>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	getAllLicenses(clientId: string): Observable<LicenseForm[]> {
		return this.httpClient
			.get<LicenseForm[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}`)
			.pipe();
	}

	searchLicenses(clientId: string, request: GetLicensesRequestViewModel): Observable<LicenseForm[]> {
		return this.httpClient
			.post<LicenseForm[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/search`, request)
			.pipe();
	}

	getFeeTypes(clientId: string, area: string): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/feeTypes/${area}`)
			.pipe();
	}

	getAreas(clientId: string): Observable<string[]> {
		return this.httpClient
			.get<string[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/feeAreas`)
			.pipe();
	}

	getFeeSchedule(clientId: string): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/fee-schedule`)
			.pipe();
	}

	getFeeScheduleInfo(clientId: string, feeCode: string): Observable<any> {
		return this.httpClient
			.get<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/fee-schedule/${feeCode}`)
			.pipe();
	}

	createInvoice(
		clientId: string,
		licenseId: string,
		fiscalYear: any,
		calculateScheduledFee: any
	): Observable<LicenseForm> {
		return this.httpClient
			.post<LicenseForm>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/${fiscalYear}/invoices?calculateScheduleFee=${calculateScheduledFee}`,
				{}
			)
			.pipe();
	}

	RegenerateCertificate(clientId: string, licenseId: string): Observable<LicenseForm> {
		return this.httpClient
			.post<LicenseForm>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/certificates`,
				{}
			)
			.pipe();
	}

	createAllInvoice(clientId: string, fiscalYear: number, calculateScheduledFee: any): Observable<any> {
		return this.httpClient
			.post<any>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/invoices/${fiscalYear}?calculateScheduleFee=${calculateScheduledFee}`,
				{}
			)
			.pipe();
	}

	createAllUnpaidInvoice(clientId: string, fiscalYear: number): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/unpaid-invoices/${fiscalYear}`, {})
			.pipe();
	}

	createAllCertificates(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/certificates`, {})
			.pipe();
	}

	emailAllInvoice(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/email-invoices`, {})
			.pipe();
	}

	emailAllUnpaidInvoice(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/email-unpaid-invoices`, {})
			.pipe();
	}

	generateErrorReport(clientId: string): Observable<any> {
		return this.httpClient
			.post<LicenseForm>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/invoices/invalid`, {})
			.pipe();
	}

	disableLicense(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${formId}`)
			.pipe();
	}

	refundInvoice(clientId: string, licenseId: string, invoiceId: number, model: RefundEntryViewModel): Observable<any> {
		return this.httpClient
			.post<RefundEntryViewModel>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/invoices/${invoiceId}/refunds`,
				model
			)
			.pipe();
	}

	getRefund(clientId: string, licenseId: string, invoiceId: number, refundId: number): Observable<any> {
		return this.httpClient
			.get<RefundEntryViewModel>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/invoices/${invoiceId}/refunds/${refundId}`
			)
			.pipe();
	}

	updateFee(clientId: string, licenseId: string, id: number, model: any): Observable<LicenseFeeViewModel[]> {
		return this.httpClient
			.post<LicenseFeeViewModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/fees/${id}`,
				model
			)
			.pipe();
	}

	updateContact(clientId: string, licenseId: string, id: number, model: any): Observable<ContactViewModel[]> {
		return this.httpClient
			.post<ContactViewModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/contacts/${id}`,
				model
			)
			.pipe();
	}

	getbatch(clientId: string, batchType: string): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/batch/${batchType}`)
			.pipe();
	}

	emailInvoicesForLicense(clientId: string, licenseId: string): Observable<LicenseForm> {
		return this.httpClient
			.post<any>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/email-license-invoices`,
				{}
			)
			.pipe();
	}

	inspectionReport(clientId: string, request: any): Observable<InspectionReportResponseModel[]> {
		return this.httpClient
			.post<InspectionReportResponseModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/inspection-report`,
				request
			)
			.pipe();
	}

	exportToExcel(clientId: string, request: any) {
		return this.httpClient
			.get(
				`${environment.apiUrl}licensing/clients/${clientId}/${
					this.urlPath
				}/export?${this.utilityService.objectToQueryString(request)}`,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}

	printRentalInspectionNotices(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/print-rental-inspection-notices`,
				request
			)
			.pipe();
	}
	updateInspection(clientId: string, licenseId: string, id: number, request: any): Observable<InspectionViewModel[]> {
		return this.httpClient
			.post<InspectionViewModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/inspection/${id}`,
				this.setupRequest(request)
			)
			.pipe();
	}

	getLastInvoice(clientId: string, licenseId: string): Observable<InvoiceViewModel[]> {
		return this.httpClient
			.get<InvoiceViewModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/last-invoice`
			)
			.pipe();
	}

	overpaymentbalance(clientId: string, licenseId: string): Observable<RemainingOverpaymentView[]> {
		return this.httpClient
			.get<RemainingOverpaymentView[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/overpaymentbalance`
			)
			.pipe();
	}

	overpaymentbalanceforrefund(clientId: string, licenseId: string): Observable<RemainingOverpaymentView[]> {
		return this.httpClient
			.get<RemainingOverpaymentView[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/overpaymentbalanceforrefund`
			)
			.pipe();
	}

	feePayment(clientId: string, licenseId: string, id: number, model: any): Observable<LicenseFeeViewModel[]> {
		return this.httpClient
			.post<LicenseFeeViewModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/payment/${id}`,
				model
			)
			.pipe();
	}

	feeOverpayment(clientId: string, licenseId: string, model: any): Observable<LicenseFeeViewModel[]> {
		return this.httpClient
			.post<LicenseFeeViewModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${licenseId}/overpayment`,
				model
			)
			.pipe();
	}

	processLateFees(clientId: string, model: any): Observable<LicenseFeeViewModel[]> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/applylatefees`, model)
			.pipe();
	}

	licensesWithOverpayment(clientId: string): Observable<LicenseForm[]> {
		return this.httpClient
			.get<LicenseForm[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/licenseswithoverpayment`)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	businessPaymentReport(clientId: string, request: any): Observable<BusinessPaymentResponseModel[]> {
		return this.httpClient
			.post<BusinessPaymentResponseModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/business-payment-report`,
				request
			)
			.pipe();
	}
	getBusinessPaymentReportDetail(clientId: string, request: any): Observable<BusinessPaymentDetailResponseModel[]> {
		return this.httpClient
			.post<BusinessPaymentDetailResponseModel[]>(
				`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/business-payment-report-detail`,
				request
			)
			.pipe();
	}

	businessPaymentReportExport(clientId: string, request: any, filter: any) {
		return this.httpClient
			.post(
				`${environment.apiUrl}licensing/clients/${clientId}/${
					this.urlPath
				}/business-payment-report-export?${this.utilityService.objectToQueryString(request)}`,
				filter,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}
	getContactTypes(clientId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/getContactTypes`)
			.pipe();
	}
	getPhoneTypes(clientId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/getPhoneTypes`)
			.pipe();
	}
	licenseReport(clientId: string, request: any): Observable<any[]> {
		return this.httpClient
			.post<any[]>(`${environment.apiUrl}licensing/clients/${clientId}/${this.urlPath}/license-report`, request)
			.pipe();
	}
	exportLicenseToExcel(clientId: string, request: any) {
		return this.httpClient
			.get(
				`${environment.apiUrl}licensing/clients/${clientId}/${
					this.urlPath
				}/export-license-report?${this.utilityService.objectToQueryString(request)}`,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}
}
