import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersonnelView } from 'app/models/form/budget/PersonnelView';
import { UtilityService } from 'app/services/utility.service';
import { environment } from 'environments/environment';
import { BaseFormService } from 'app/services/BaseFormService';
import { map } from 'rxjs/operators';
import { PersonnelBudgetForm } from 'app/models/form/budget/PersonnelBudgetForm';

@Injectable({
	providedIn: 'root',
})
export class PersonnelBudgetServices extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/personnel-budget`, httpClient, utilityService);
	}

	getSalary(clientId: string, employeeId: string, employeeView: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/personnel/${employeeId}/wages`, employeeView).pipe();
	}

	getBudgetForDepartment(clientId: string, departmentId: any, fiscalYear: number): Observable<PersonnelBudgetForm> {
		return this.httpClient
			.get<PersonnelBudgetForm>(`${this.getUrl(clientId)}/department/${departmentId}?fiscalyear=${fiscalYear}`)
			.pipe(
				map((data) => {
					if (data.id == '00000000-0000-0000-0000-000000000000') {
						data.id = '00000000-0000-0000-0000-000000000000';
					}
					return data;
				})
			);
	}

	getAvailableBudgets(clientId: string, fiscalYear: number): Observable<PersonnelBudgetForm[]> {
		return this.httpClient.get<PersonnelBudgetForm[]>(
			`${this.getUrl(clientId)}/available-forms?fiscalyear=${fiscalYear}`
		);
	}

	exportDepartmentToExcel(clientId: string, departmentId: number, fiscalYear: number) {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/department/${departmentId}/export?fiscalYear=${fiscalYear}`, {
				responseType: 'blob',
			})
			.pipe();
	}

	getPersonalDetail(clientId: string, fiscalYear: number): Observable<PersonnelView[]> {
		return this.httpClient
			.get<PersonnelView[]>(`${this.getUrl(clientId)}/report/personnel?fiscalYear=${fiscalYear}`)
			.pipe();
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	isBudgetFormSubmittedForPosition(clientId: string, positionId: any, fiscalYear: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/IsBudgetFormSubmittedForPosition/${positionId}/year/${fiscalYear}`)
			.pipe();
	}
	revertPersonnelBudgetForm(clientId: string, personnelBudgetId: any): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.getUrl(clientId)}/${personnelBudgetId}/RevertPersonnelBudgetForm`, {})
			.pipe();
	}
	isBudgetFormSubmittedForDepartment(clientId: string, departmentId: any, fiscalYear: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/IsBudgetFormSubmittedForDepartment/${departmentId}/year/${fiscalYear}`)
			.pipe();
	}
}
