import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UtilityService } from 'app/services/utility.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(private toastr: ToastrService, private utilityService: UtilityService) {}

	showSuccess(message, title: string = '') {
		this.toastr.success(message, title);
	}

	showError(message, title: string = 'Error') {
		this.toastr.error(this.utilityService.getErrorMessage(message), title);
	}

	showInfo(message, title = '') {
		this.toastr.info(message, title);
	}

	showWarning(message, title) {
		this.toastr.warning(message, title);
	}
}
